/* eslint-disable no-alert */
import { css } from '@emotion/core'
import { Formik } from 'formik'
import React from 'react'
import { Helmet } from 'react-helmet'
import * as Yup from 'yup'
import { Box, Field, Layout, Link } from '../components'
import { IconEmail, IconUser } from '../icons'
import { domain } from '../stores/account'

const container = css`
  margin: 0 auto;
  margin-top: 6rem;
  max-width: 36rem;
  padding: 0 2rem;
`

const header = css`
  font-size: 72px;
  font-weight: bold;
  color: #3525e6;
  text-align: center;
  text-decoration: none;
`

const card = css`
  margin-top: 4rem;
  padding: 4rem;
  box-shadow: 0px 18px 18px rgba(10, 31, 68, 0.12), 0px 0px 12px rgba(10, 31, 68, 0.1);
  border-radius: 4px;
`

const h2 = css`
  font-size: 32px;
  font-weight: 500;
  text-align: center;
`

const span = css`
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #8a94a6;
`

const loginPageLink = css`
  color: #3525e6;
  &:hover {
    text-decoration: underline;
  }
`

const buttonContainer = css`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
`

const button = css`
  padding: 1rem 4rem;
  border: none;
  border-radius: 100px;
  font-size: 18px;
  font-weight: 500;
  color: white;
  transition: 300ms ease;
  background: linear-gradient(to right, #386aeb 10%, #ad31e7 100%);
  &:hover {
    cursor: pointer;
    box-shadow: rgba(53, 37, 230, 0.3) 0px 10px 10px;
    transform: translateY(-4px);
  }
`

const RegisterPage = () => (
  <Layout>
    <Helmet>
      <title>Maker Traffic - Register</title>
    </Helmet>
    <div css={container}>
      <Link to="/">
        <h1 css={header}>Maker Traffic</h1>
      </Link>
      <div css={card}>
        <h2 css={h2}>Create a new account</h2>
        <div css={span}>
          <span>Already have an account? </span>
          <Link to="/login">
            <span css={loginPageLink}>Login here</span>
          </Link>
        </div>

        <Formik
          initialValues={{ fullname: '', email: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const result = await fetch(`${domain}/register`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: values.fullname, email: values.email }),
              })

              if (result.ok) {
                alert('Your account has been created! You will be redirected to the login page :)')
                window.location.href = `${window.origin}/login`
              } else {
                alert('Your account already exist. Please go to the login page.')
              }
            } catch (error) {
              console.log(error)
              alert('Some error happened..')
            }

            setSubmitting(false)
          }}
          validationSchema={Yup.object().shape({
            fullname: Yup.string().required(),
            email: Yup.string()
              .email()
              .required(),
          })}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {props => {
            const { values, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props
            return (
              <form onSubmit={handleSubmit}>
                <Box mt="4rem">
                  <Field
                    title="Full name"
                    placeholder="Enter your full name"
                    value={values.fullname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.fullname}
                    big
                  >
                    <IconUser color="#C9CED6" />
                  </Field>
                </Box>
                <Box mt="2rem">
                  <Field
                    title="Email"
                    placeholder="Enter your email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email}
                    big
                  >
                    <IconEmail color="#C9CED6" />
                  </Field>
                </Box>
                <div css={buttonContainer}>
                  <button css={button} type="button" disabled={isSubmitting} onClick={handleSubmit}>
                    {isSubmitting ? 'Loading...' : 'Create'}
                  </button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  </Layout>
)

export default RegisterPage
